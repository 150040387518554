var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-row',[_c('v-col',[[_c('v-card',[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-card-title',{staticClass:"pb-10"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.exportTableToCSV('COD_Payment.csv')}}},[_c('v-icon',{staticClass:"pr-5",attrs:{"large":"","color":"red"}},[_vm._v("mdi-download ")])],1),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.print}},[_c('v-icon',{staticClass:"pr-5",attrs:{"large":"","color":"red"}},[_vm._v("mdi-printer ")])],1)],1)],1),_c('v-col',{staticClass:"d-flex flex-row",attrs:{"cols":"8"}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"padding":"0px !important"},attrs:{"label":"From Date","prepend-icon":"mdi-calendar","readonly":"","dense":""},model:{value:(_vm.dateto1),callback:function ($$v) {_vm.dateto1=$$v},expression:"dateto1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.dateto1),callback:function ($$v) {_vm.dateto1=$$v},expression:"dateto1"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"padding":"0px !important"},attrs:{"label":"To Date","prepend-icon":"mdi-calendar","readonly":"","dense":""},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}})],1)],1),_c('v-btn',{staticClass:"mr-2 font-weight-bold",staticStyle:{"background":"gray"},attrs:{"text":""},on:{"click":_vm.seereport}},[_vm._v(" Submit ")])],1)],1),_c('div',{staticClass:"mt-4",attrs:{"id":"dataapp"}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"background":"#f5f5f5"},attrs:{"headers":_vm.codHeaders,"items":_vm.codPayment,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading... Please wait","no-data-text":"No orders available","item-key":"id","server-items-length":_vm.totalItems,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.coupon",fn:function(ref){
var item = ref.item;
return [(item.coupon_code)?_c('div',[_vm._v(_vm._s(item.coupon_code))]):_c('div',[_vm._v("N/A")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"padding-left":"0px !important"},attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.viewDetailsToConfirmOrder(item)}}},[_vm._v("Details")])]}},{key:"item.actions_invoice",fn:function(ref){
var item = ref.item;
return [(item.admin_status == 'Processing')?_c('v-btn',{staticStyle:{"padding-left":"0px !important"},attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.viewInvoice(item)}}},[_vm._v("Invoice ")]):_vm._e()]}}])},[_vm._v(" --> ")])],1),_c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('div',{staticClass:"col-4"},[_vm._v(" Total "+_vm._s(_vm.totalItems)+" records ")]),_c('div',{staticClass:"col-4"},[_c('v-select',{attrs:{"dense":"","outlined":"","hide-details":"","label":"Items per page","items":_vm.pageSizes},on:{"change":function($event){return _vm.handlePageSizeChange(_vm.perPage)}},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('div',{staticClass:"col-4"},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":"7"},on:{"input":_vm.handlePageChange},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }