<template>
    <v-container fluid>
        <v-row>
            <v-row>
                <v-col>
                    <template>
                        <v-card>
                            <v-row class="mt-5">
                                <!-- Data print & download start  -->
                                <v-col cols="4">
                                    <v-card-title class="pb-10">
                                        <v-btn @click="exportTableToCSV('COD_Payment.csv')" text>
                                            <v-icon class="pr-5" large color="red">mdi-download </v-icon>
                                        </v-btn>
                                        <v-btn text @click="print">
                                            <v-icon class="pr-5" large color="red">mdi-printer </v-icon>
                                        </v-btn>
                                    </v-card-title>
                                </v-col>
                                <!-- Data print & download end  -->
                                <!-- date filtering start -->
                                <v-col cols="8" class="d-flex flex-row">
                                    <v-spacer></v-spacer>
                                    <v-col cols="2">
                                        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="dateto1" label="From Date"
                                                    prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense
                                                    style="padding:0px !important"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="dateto1" @input="menu = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="dateto" label="To Date"
                                                    prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense
                                                    style="padding:0px !important"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="dateto" @input="menu2 = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>                                  
                                    <v-btn text @click="seereport" class="mr-2 font-weight-bold" style="background: gray">
                                        Submit
                                    </v-btn>
                                </v-col>
                                <!-- date filtering end -->
                            </v-row>
                            <!-- hide-default-footer -->
                            <!-- data table start  -->
                            <div id="dataapp" class="mt-4">
                                <v-data-table :headers="codHeaders" :items="codPayment" :search="search"
                                    :loading="loading" loading-text="Loading... Please wait"
                                    no-data-text="No orders available" item-key="id" :server-items-length="totalItems"
                                    hide-default-footer class="elevation-1" style="background: #f5f5f5">
                                   
                                        <template v-slot:item.coupon="{ item }">
                                        <div v-if="item.coupon_code">{{ item.coupon_code }}</div>
                                        <div v-else>N/A</div>
                                        </template> -->

                                    <template v-slot:item.actions="{ item }">
                                        <v-btn color="primary" style="padding-left: 0px !important" text
                                            @click="viewDetailsToConfirmOrder(item)">Details</v-btn>
                                    </template>

                                    <template v-slot:item.actions_invoice="{ item }">
                                        <v-btn v-if="item.admin_status == 'Processing'" color="primary"
                                            style="padding-left: 0px !important" text @click="viewInvoice(item)">Invoice
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </div>
                            <!-- data table end  -->
                            <!-- pagination start  -->
                            <div class="d-flex justify-content-center mt-5">
                                <!-- <v-col cols="3" md="3"> -->
                                <div class="col-4">
                                    Total {{ totalItems }} records
                                </div>
                                <!-- </v-col> -->
                                <!-- <v-col cols="3" md="3"> -->
                                <div class="col-4">
                                    <v-select dense outlined hide-details label="Items per page" v-model="perPage"
                                        :items="pageSizes" @change="handlePageSizeChange(perPage)">
                                    </v-select>
                                </div>
                                <!-- </v-col> -->
                                <!-- <v-col cols="6" md="3" > -->
                                <div class="col-4">
                                    <v-pagination v-model="currentPage" :length="totalPages" total-visible="7"
                                        @input="handlePageChange">
                                    </v-pagination>
                                </div>

                                <!-- </v-col> -->
                            </div>
                            <!-- pagination end  -->
                        </v-card>
                    </template>
                </v-col>
            </v-row>
        </v-row>
    </v-container>
</template>
    
<script>
export default {
    data: () => ({
        expanded: [],
        loading: true,
        date: new Date().toISOString().substr(0, 2),
        search: "",
        perPage: '10',
        currentPage: 1,
        totalItems: 0,
        totalPages: 0,
        pagination: {},
        pageSizes: ['10', '30', '50', '100', 'All'],
        singleExpand: false,
        dateto1: null,
        dateto: null,
        menu2: false,
        menu: false,
        order_status: null,
        codHeaders: [{
            text: "Order ID",
            align: "start",
            value: "id",
        },
        {
            text: "Order Date",
            value: "ordered_date"
        },
        {
            text: "Customer Name",
            value: "billing_address.name",
            sortable: false
        },
        {
            text: "Mobile Number",
            value: "billing_address.phone_number",
            sortable: false
        },
        {
            text: "Location",
            value: "billing_address.location"
        },

        {
            text: "Payment Type",
            value: "payment_method"
        },
        {
            text: "Price",
            value: "price_total"
        },
        ],
        codPayment: [],
    }),

    methods: {
        async initialize() {
            let perPage;
            if (this.perPage === 'All') {
                perPage = this.totalItems;
            }
            else {
                perPage = this.perPage;
            }
            this.loading = true;
            await axios.get(`Cart/payment-information-cod/?page=${this.currentPage}&size=${perPage}&FromDate=${this.dateto1}&ToDate=${this.dateto}`).then((response) => {
                // console.log('response = ', response.status);
                if (response.status === 200) {
                    this.loading = false;
                    this.totalItems = response.data.count;
                    this.totalPages = parseInt(this.totalItems / this.perPage);
                    this.codPayment = response.data.results;
                }
            });
        },
        handlePageSizeChange(value) {
            // console.log('page size change method called');
            // console.log('value = ', value);
            this.perPage = (value);
            // console.log('loading = ', this.loading);
            this.loading = true;
            // console.log('loading = ', this.loading);
            this.initialize();

        },

        handlePageChange(value) {
            // console.log('handle page change value = ', value);
            this.currentPage = value;
            // console.log('loading = ', this.loading);
            this.loading = true;
            // console.log('loading = ', this.loading);
            this.initialize();
            window.scrollTo(0, 0);
        },
        viewInvoice(item) {
            if (item.reference_id == 0) {
                this.$router.push({
                    path: "/dashboard/pages/orderinvoice_updated",
                    query: {
                        id: item.id
                    },
                });
            } else {
                this.$router.push({
                    path: "/dashboard/pages/newInoice_page",
                    query: {
                        id: item.id
                    },
                });
            }
        },

        viewMotherInvoice(item) {
            if (item.reference_id == 0) {
                this.$router.push({
                    path: "/dashboard/pages/orderinvoice_updated",
                    query: {
                        id: item.id
                    },
                });
            } else {
                // console.log("clicked");
                this.$router.push({
                    path: "/dashboard/pages/motherinvoice_updated",
                    query: {
                        id: item.reference_id
                    },
                });
            }
        },
        downloadCSV(csv, filename) {
            var csvFile;
            var downloadLink;

            csvFile = new Blob([csv], { type: 'text/csv' });
            downloadLink = document.createElement("a");
            downloadLink.download = filename;
            downloadLink.href = window.URL.createObjectURL(csvFile);
            downloadLink.style.display = "none";

            document.body.appendChild(downloadLink);
            downloadLink.click();
        },

        exportTableToCSV(filename) {


            var csv = [];
            var rows = document.querySelectorAll(" #dataapp table tr");

            for (var i = 0; i < rows.length; i++) {
                var row = [], cols = rows[i].querySelectorAll("td, th");
                for (var j = 0; j < cols.length; j++)
                    row.push(cols[j].innerText);
                csv.push(row.join(","));
            }

            this.downloadCSV(csv.join("\n"), filename);
        },

        print() {
            var divToPrint = document.getElementById("dataapp");
            var style = "<style>";
            style = style + "table {width: 100%;font: 17px Calibri;}";
            style =
                style +
                "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
            style = style + "padding: 2px 3px;text-align: center;}";
            style = style + "</style>";

            var win = window.open("", "", "height=1900,width=1900");
            win.document.write(style); //  add the style.
            win.document.write(divToPrint.outerHTML);
            win.document.close();
            win.print();
        },

        fromdate() { },
        seereport() {
            // console.log('this from  date is :', this.dateto1);
            // console.log('this to  date is :', this.dateto);
            this.initialize();
        },
        todate() { },       
    },
    mounted() {
        this.initialize();
    },
};
</script>
    
<style>

</style>
    